import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Java',
    'Spring Boot',
    'JavaScript (ES6+)',
    'TypeScript',
    'React',
    'AWS (EC2, S3, Lambda)',
    'Node.js',
    'Docker & Kubernetes',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Sachin, and I'm passionate about creating impactful digital
              experiences. My journey in tech began with building data pipelines and scalable
              applications, sparking a deep interest in how software shapes the world around us.
            </p>

            <p>
              Since then, I've had the opportunity to work at <a href="https://www.tcs.com/"> TCS</a>, a major global corporation, where I designed
              secure APIs, cross-platform apps, and complex cloud solutions that boosted user
              engagement and streamlined operations. I’ve also enjoyed guiding students as a
              Teaching Assistant at  <a href="https://www.stevens.edu/"> Stevens Institute of
              Technology</a>, helping them dive into the world of full-stack development with tools like
              Node.js, MongoDB, and TypeScript.
            </p>

            <p>
              Currently, I’m a Full Stack Developer at <a href="https://www.lpl.com/"> LPL Financial</a>, 
              where I focus on building secure, high-performance financial solutions. I lead the 
              development of microservices-based applications, optimize front-end experiences with 
              React and TypeScript, and enhance real-time event processing with Apache Kafka. 
            </p>

            <p>
              Today, my focus is on developing reliable, secure, and user-friendly software
              solutions. I’m always excited to work on projects that integrate cutting-edge
              technologies, from <a href="https://aws.amazon.com/"> AWS </a> and{' '} <a href="https://kubernetes.io/">Kubernetes</a> to 
              JavaScript frameworks and real-time data visualization.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
